.float_bg {
    /* background-color: red; */
    width: 100%;
    text-align: center;
    position: fixed;
    top: 50%;
}

.float_bg div {
    transform: rotateZ(90deg);
    position: relative;
    left: 126px;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    background: hsla(208, 77%, 49%, 0.3);
    color: black;
    cursor: pointer;
}

.float_img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}
