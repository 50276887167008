.App {
  text-align: center;
  padding: 20px;
}

.icon-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.icon-item {
  width: 150px; /* Fixed width */
  height: 200px; /* Fixed height */
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.icon-item:hover {
  transform: scale(1.05);
}

.icon-image {
  width: 30%;
  height: auto;
  object-fit: contain; /* Ensure images fit within the specified dimensions */
  margin-bottom: 10px;
}

.icon-title {
  font-size: 16px;
  font-weight: bold;
  margin-top: 12px;
  font-size: 18px;
  color: #FF7917;
}

.icon-description {
  font-size: 10px;
  margin-top: 8px;  
  color: #666;
}
