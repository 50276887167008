.contact-form-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 60px;
  }
  
  .form-section {
    background-color: #d4e0f4;
    text-align: left;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    min-width: 40%;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    margin-left: 50px;
    font-weight: bold;
    text-align: left;
  }
  
  .form-group input {
    width: 90%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 30px;
    text-align: left;
  }

  .form-group select {
    width: 90%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 30px;
  
  }
  .form-button {
    padding: 10px 15px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .form-button:hover {
    background-color: #0056b3;
  }
  
  .contact-info {
    display: flex;
    flex-direction: column;
    gap: 80px;
    background-color: #D9C884;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
  }
  
  @media (min-width: 768px) {
    .contact-form-container {
      flex-direction: row;
      justify-content: space-between;
    }
  
    .form-section, .contact-info {
      width: 48%;
    }

    @media (max-width: 500px) {
      .form-group input {
        font-size: 70px
      }

      .form-group option {
        font-size: 70px;
      }
      
    }

    

  }
  