.responsive-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 20px;
    text-align: justify;
  }
  
  .content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    align-items: center;
  }
  
  .image {
    width: 100%;
    height: auto;
  }
  
  @media (max-width: 768px) {
    .responsive-section {
      grid-template-columns: 1fr;
    }
    .content {
      grid-template-columns: 1fr;
    }
  }
  