.partner-logos {
    background-color: #12223D;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px; /* Adjust gap between logos */
    padding: 20px;
  }
  
  .partner-logo {
    max-width: 250px; /* Adjust max width of logos */
    height: auto;
  }
  
  @media (max-width: 768px) {
    .partner-logos {
      flex-direction: column;
      align-items: center;
    }
  
    .partner-logo {
      margin-bottom: 20px; /* Adjust margin between logos in column */
    }
  }
  