.video {
    padding: 2rem;
    background: #c4cbd8;
  }
.video-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;    
    align-items: center;
    max-width: 1120px;
    min-width: 200px;
    width: 90%;
    margin: 0 auto;
  }
  
  .video-item {
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 10px;
    padding: 10px;
    width: 300px;
    cursor: pointer;
    text-align: center;
  }
  
  .video-item img {
    width: 100%;
    max-height: max-content;
    border-radius: 4px;
  }
  
  .video-item h3 {
    font-size: 100%;
    margin: 10px 0;
  }
  
  .play-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .video-player {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .video-player video {
    width: 60%;
    height: auto;    
    max-width: 800px;
    border: none;
  }
  
  .close-button {
    position: absolute;
    top: 90px;
    right: 20px;
    background-color: red;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }

  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }