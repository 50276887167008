.app {
    text-align: center;
    padding: 20px;
  }
  
  .testimonials-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
.testimonial {
    width: 100%;
    max-width: 400px;
    margin: 10px;
    padding: 20px;
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: transform 0.3s ease;
    cursor: pointer;
  }
  
  .testimonial:hover {
    transform: scale(1.05);
  }
  
  .testimonial.clicked {
    transform: scale(1.1);
  }
  
  .testimonial p {
    font-size: 1.2em;
    margin: 0 0 10px;
  }
  
  .testimonial h4 {
    font-size: 1em;
    margin: 0;
    text-align: right;
    color: #555;
  }
  
  @media (max-width: 600px) {
    .testimonial {
      max-width: 100%;
      padding: 15px;
    }
  
    .testimonial p {
      font-size: 1em;
    }
  
    .testimonial h4 {
      font-size: 0.9em;
    }
  }
  