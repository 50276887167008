
video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }
  
  .hero-container {
    background-color: #12223D;
    background: url('../images/Final2.jpg') center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }

  .hero-thick-outlined-text {
    
    color: #fff;
    margin-top: -100px;
    font-style: italic;
    text-shadow:
        -2px -2px 0 #182d4f,
         2px -2px 0 #82b7f8,
        -2px  2px 0 #c3f068,
         2px  2px 0 #f957d9,
        -2px  0   0 #f05f5f,
         2px  0   0 #8cfab0,
         0  -2px 0 #348763,
         0   2px 0 #ef6ea6;
}
  
  .hero-container > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
  }
  
  .hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-btns {
    margin-top: 32px;
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }

  @media (max-width: 768px) {
    .hero-container {
        background-image: url('../images/mobile11.jpg');
        object-fit: contain;
    }
}

@media (max-width: 450px) {
  .hero-container {
      background-image: url('../images/trial.jpg');
      object-fit: contain;
  }
}
  
  @media screen and (max-width: 960px) {
    .hero-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .hero-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .hero-container > p {
      font-size: 30px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  }